.video-modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .75);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    padding: 2rem;
  }
  
  .video-modal-content {
    position: relative;
    width: 100%;
    max-width: 900px;
    background-color: #000;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .video-modal-wrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    height: 0;
    overflow: hidden;
  }
  
  .video-modal-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .video-modal-close {
    position: absolute;
    top: -40px;
    right: 0;
    background: none;
    border: none;
    color: white;
    font-size: 2rem;
    cursor: pointer;
    padding: 0.5rem;
    z-index: 1;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .video-modal-close:hover {
    opacity: 0.8;
  }
  
  @media (max-width: 768px) {
    .video-modal-backdrop {
      padding: 1rem;
    }
  }
  
  @media (max-width: 480px) {
    .video-modal-backdrop {
      padding: 0.5rem;
    }
  }
  
  @media (prefers-reduced-motion: reduce) {
    .video-modal-backdrop {
      transition: none;
    }
  }