.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: var(--header-bg);
  z-index: 1000;
  height: 64px;
  display: flex;
  align-items: center;
  box-shadow: 0 2px 4px #0000001a;
}

[data-theme='light'] .header {
  background-color: #f8f9fa;
}

.header-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 3rem;
}

.logo {
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.logo-image {
  height: 28px;
  width: auto;
  object-fit: contain;
}

.nav-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  gap: 3rem;
}

.nav-links {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex-shrink: 0;
}

.nav-links a {
  color: var(--nav-text);
  text-decoration: none;
  font-size: 11px;
  font-weight: 500;
  padding: 0.35rem 0.75rem;
  border-radius: 16px;
  transition: all 0.2s ease;
  background-color: var(--nav-bg);
  border: 2px solid transparent;
}

[data-theme='light'] .nav-links a {
  background-color: #e9ecef;
  color: black;
}

.nav-links a:hover {
  background-color: var(--nav-hover);
}

[data-theme='light'] .nav-links a:hover {
  background-color: #e9ecef;
}

.nav-links a.active {
  background-color: var(--nav-bg);
  border-color: var(--nav-border);
  color: var(--nav-text);
  font-weight: 500;
}

[data-theme='light'] .nav-links a.active:hover {
  background-color: #e9ecef;
}

[data-theme='light'] .nav-links a.active {
  background-color: #e9ecef;
  color: black;
}

.search-section {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  max-width: 600px;
  width: 100%;
  position: relative;
  margin-left: auto;
}

.search-bar {
  display: flex;
  align-items: center;
  background-color: var(--search-bg);
  border-radius: 25px;
  width: 100%;
  height: 36px;
  position: relative;
  overflow: hidden;
}

[data-theme='light'] .search-bar {
  background-color: white;
  border: 1px solid #e9ecef;
}

.region-select-container {
  position: relative;
  min-width: 100px;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}

[data-theme='light'] .region-select-container {
  border-right: 1px solid #e9ecef;
}

.region-select {
  appearance: none;
  background: transparent;
  border: none;
  color: var(--text-primary);
  padding: 6px 12px 7px 12px;
  height: 36px;
  font-size: 0.8125rem;
  cursor: pointer;
  width: 100%;
}

[data-theme='light'] .region-select {
  color: #666;
}

[data-theme=dark] .region-select {
  background: #2a2a2a;
}

.region-select:focus {
  outline: none;
}

.region-select-arrow {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  color: var(--text-primary);
  opacity: 0.5;
}

[data-theme='light'] .region-select-arrow {
  color: #666;
}

.search-content {
  display: flex;
  align-items: center;
  flex-grow: 1;
  padding: 4px 12px;
  position: relative;
}

.search-bar-content {
  display: flex;
  align-items: center;
  gap: 6px;
  width: calc(100% - 44px);
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.search-bar-content::-webkit-scrollbar {
  display: none;
}

.tags {
  display: flex;
  align-items: center;
  gap: 6px;
  flex-shrink: 0;
  overflow-x: visible;
  white-space: nowrap;
}

.tag {
  display: inline-flex;
  align-items: center;
  background-color: #0000001a;
  color: var(--text-primary);
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 0.8125rem;
  font-weight: 500;
  gap: 4px;
  transition: all 0.2s ease;
  white-space: nowrap;
  flex-shrink: 0;
}

.tag:hover {
  background-color: var(--accent);
  color: white;
}

.tag-close {
  background: none;
  border: none;
  color: var(--text-primary);
  font-size: 1rem;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.2s ease;
  width: 14px;
  height: 14px;
  line-height: 1;
}

.tag-close:hover {
  color: var(--accent);
}

.tag:hover .tag-close {
  color: white;
}

.search-input-wrapper {
  flex: 1;
  min-width: 60px;
  overflow: hidden;
}

.search-bar input {
  border: none;
  background: none;
  outline: none;
  width: 100%;
  color: var(--text-primary);
  font-size: 0.8125rem;
  padding: 0;
}

[data-theme='light'] .search-bar input {
  color: #666;
}

.search-bar input::placeholder {
  color: var(--text-secondary);
  opacity: 0.7;
}

.search-bar input:focus::placeholder {
  opacity: 0;
}

.search-bar input:disabled {
  cursor: not-allowed;
  opacity: 0.7;
}

.search-button {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  color: var(--text-primary);
  cursor: pointer;
  transition: color 0.2s ease;
}

[data-theme='light'] .search-button {
  border-left: 1px solid #e9ecef;
  color: #666;
}

.search-button:hover {
  color: var(--accent);
}

.suggestion-list {
  position: absolute;
  top: calc(100% + 6px);
  left: 0;
  right: 0;
  background-color: var(--header-bg);
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

[data-theme='light'] .suggestion-list {
  background-color: white;
  border: 1px solid #e9ecef;
}

.suggestion-item {
  padding: 8px 12px;
  cursor: pointer;
  font-size: 0.8125rem;
  transition: background-color 0.2s ease;
}

.suggestion-item:hover {
  background-color: var(--nav-hover);
}

[data-theme='light'] .suggestion-item:hover {
  background-color: #f8f9fa;
}

.mobile-buttons {
  display: none;
  gap: 1rem;
  align-items: center;
  margin-left: auto;
  margin-right: -0.5rem;
}

.mobile-button {
  background: none;
  border: none;
  color: var(--nav-text);
  padding: 0.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  border-radius: 4px;
}

[data-theme='dark'] .mobile-button {
  color: #ffffff;
}

[data-theme='dark'] .mobile-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

[data-theme='light'] .mobile-button {
  color: #333333;
}

[data-theme='light'] .mobile-button:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.mobile-button:active {
  transform: translateY(1px);
}

.mobile-menu {
  position: fixed;
  top: 64px;
  right: -100%;
  width: 100%;
  height: calc(100vh - 64px);
  background-color: var(--header-bg);
  transition: right 0.3s ease;
  z-index: 999;
  overflow-y: auto;
}

.mobile-menu.open {
  right: 0;
}

.mobile-nav-links {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.mobile-nav-links a {
  color: var(--nav-text);
  text-decoration: none;
  font-size: 1rem;
  padding: 1rem;
  border-bottom: 1px solid var(--border-color);
  transition: background-color 0.2s ease;
}

.mobile-nav-links a:hover {
  background-color: var(--nav-hover);
}

[data-theme='light'] .mobile-menu {
  background-color: #f8f9fa;
}

[data-theme='light'] .mobile-nav-links a {
  color: #333;
  border-bottom-color: #e9ecef;
}

.mobile-search-overlay {
  position: fixed;
  top: 64px;
  left: 0;
  right: 0;
  background-color: var(--header-bg);
  padding: 1rem;
  transform: translateY(-100%);
  transition: transform 0.3s ease;
  z-index: 998;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.mobile-search-overlay.open {
  transform: translateY(0);
}

.mobile-search-overlay .search-bar {
  width: 100%;
  position: relative;
  height: 44px;
}

.mobile-search-overlay .search-content {
  height: 100%;
  padding: 0 44px 0 12px;
}

.mobile-search-overlay .search-bar-content {
  height: 100%;
  align-items: center;
  padding: 4px 0;
}

.mobile-search-overlay .tags {
  height: 100%;
  align-items: center;
  padding-right: 8px;
}

.mobile-search-overlay .search-button {
  height: 100%;
  width: 44px;
  right: 0;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  z-index: 2;
}

[data-theme='light'] .mobile-search-overlay .search-button {
  border-left: 1px solid #e9ecef;
}

.mobile-search-overlay .search-input-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
}

.mobile-search-overlay .search-bar input {
  height: 100%;
}

.mobile-search-overlay .suggestion-list {
  position: relative;
  top: 0.5rem;
  margin-top: 0.5rem;
}

@media (max-width: 1024px) {
  .header-content {
    padding: 0 1rem;
  }
  
  .search-section {
    max-width: 400px;
  }
  
  .region-select-container {
    min-width: 90px;
  }
}

@media (max-width: 768px) {
  .header-content {
    padding: 0 0.5rem 0 1.5rem;
  }
  
  .mobile-buttons {
    display: flex;
  }

  .search-section {
    display: none;
  }
  
  .nav-links {
    display: none;
  }

  .mobile-search-overlay .search-bar input,
  .mobile-search-overlay .region-select,
  .mobile-search-overlay .search-button,
  .mobile-search-overlay .tag-close {
    min-height: 44px;
  }

  .mobile-search-overlay .suggestion-item {
    padding: 0.75rem 1rem;
  }
}

@media (prefers-reduced-motion: reduce) {
  .nav-links a,
  .search-button,
  .tag,
  .tag-close,
  .suggestion-item,
  .mobile-menu,
  .mobile-button,
  .mobile-nav-links a,
  .mobile-search-overlay {
    transition: none;
  }
}

.nav-links a:focus-visible,
.search-button:focus-visible,
.region-select:focus-visible,
.mobile-button:focus-visible,
.mobile-nav-links a:focus-visible {
  outline: 2px solid var(--accent);
  outline-offset: 2px;
}