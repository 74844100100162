.footer {
    background-color: #1a1a1a;
    color: #ffffff;
    padding: 4rem 0 2rem;
    width: 100%;
  }
  
  .footer-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
    display: flex;
    justify-content: space-between;
    gap: 4rem;
  }
  
  .footer-brand {
    flex-shrink: 0;
  }
  
  .footer-logo {
    width: 220px;
    height: auto;
    margin-bottom: 1.5rem;
  }
  
  .social-links {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
  }
  
  .social-links a {
    color: #ffffff;
    opacity: 0.8;
    transition: opacity 0.2s ease;
  }
  
  .social-links a:hover {
    opacity: 1;
  }
  
  .footer-links {
    display: flex;
    gap: 6rem;
    flex-grow: 1;
    justify-content: flex-end;
  }
  
  .footer-column {
    flex-shrink: 0;
  }
  
  .footer-column h3 {
    color: #666666;
    font-size: 0.875rem;
    font-weight: 600;
    margin-bottom: 1.25rem;
    letter-spacing: 0.5px;
  }
  
  .footer-column ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .footer-column ul li {
    margin-bottom: 0.75rem;
  }
  
  .footer-column ul li:last-child {
    margin-bottom: 0;
  }
  
  .footer-column ul li a {
    color: #ffffff;
    text-decoration: none;
    font-size: 0.9375rem;
    opacity: 0.8;
    transition: opacity 0.2s ease;
  }
  
  .footer-column ul li a:hover {
    opacity: 1;
  }
  
  .footer-bottom {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem 2rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    margin-top: 3rem;
    font-size: 0.875rem;
    color: #666666;
  }
  
  .footer-bottom p {
    margin: 0;
  }
  
  .legal-links {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .legal-links a {
    color: #666666;
    text-decoration: none;
    transition: color 0.2s ease;
  }
  
  .legal-links a:hover {
    color: #ffffff;
  }
  
  .separator {
    color: #666666;
  }
  
  /* Responsive Design */
  @media (max-width: 1024px) {
    .footer-content {
      gap: 3rem;
    }
  
    .footer-links {
      gap: 4rem;
    }
  }
  
  @media (max-width: 768px) {
    .footer {
      padding: 3rem 0 1.5rem;
    }
  
    .footer-content {
      flex-direction: column;
      gap: 3rem;
    }
  
    .footer-brand {
      text-align: center;
    }
  
    .social-links {
      justify-content: center;
    }
  
    .footer-links {
      justify-content: space-between;
      gap: 2rem;
    }
  
    .footer-column {
      flex: 1;
    }
  
    .footer-bottom {
      flex-direction: column;
      text-align: center;
      gap: 1rem;
      margin-top: 2rem;
      padding-top: 1.5rem;
    }
  }
  
  @media (max-width: 600px) {
    .footer {
      padding: 2rem 0 1rem;
    }
  
    .footer-content {
      padding: 0 1rem;
      gap: 2rem;
    }
  
    .footer-links {
      flex-direction: column;
      gap: 2rem;
    }
  
    .footer-column {
      text-align: center;
    }
  
    .footer-column h3 {
      margin-bottom: 1rem;
    }
  
    .footer-bottom {
      padding: 1.5rem 1rem 0;
    }
  }
  
  /* Dark mode enhancements */
  [data-theme='dark'] .footer {
    background-color: #000000;
  }
  
  [data-theme='dark'] .footer-column h3,
  [data-theme='dark'] .footer-bottom,
  [data-theme='dark'] .legal-links a,
  [data-theme='dark'] .separator {
    color: #666666;
  }
  
  /* Accessibility */
  @media (prefers-reduced-motion: reduce) {
    .footer-column ul li a,
    .social-links a,
    .legal-links a {
      transition: none;
    }
  }
  
  /* Focus states for accessibility */
  .footer a:focus-visible {
    outline: 2px solid var(--accent);
    outline-offset: 2px;
    border-radius: 2px;
  }

  