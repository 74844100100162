.promotion-modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    padding: 1rem;
  }
  
  .promotion-modal-content {
    background-color: var(--background);
    border-radius: 8px;
    width: 100%;
    max-width: 400px;
    position: relative;
    padding: 1.5rem;
  }
  
  .promotion-modal-close {
    position: absolute;
    top: 0.75rem;
    right: 0.75rem;
    background: none;
    border: none;
    font-size: 1.25rem;
    color: var(--text-primary);
    cursor: pointer;
    padding: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }
  
  .promotion-modal-header {
    margin-bottom: 1rem;
  }
  
  .promotion-modal-header h2 {
    font-size: 1.25rem;
    font-weight: 600;
    color: var(--text-primary);
    margin-bottom: 0.75rem;
  }
  
  .promotion-details {
    background-color: var(--secondary-bg);
    padding: 0.75rem;
    border-radius: 4px;
    margin-top: 0.75rem;
  }
  
  .promotion-details h3 {
    font-size: 0.875rem;
    font-weight: 600;
    color: var(--text-primary);
    margin-bottom: 0.25rem;
  }
  
  .promotion-details p {
    font-size: 0.75rem;
    color: var(--text-secondary);
    margin-bottom: 0.25rem;
  }
  
  .promotion-details .price {
    display: flex;
    align-items: baseline;
    gap: 0.25rem;
    margin-top: 0.25rem;
  }
  
  .promotion-details .price span {
    font-size: 0.875rem;
    font-weight: 600;
    color: var(--text-primary);
  }
  
  .promotion-details .price .details {
    font-size: 0.75rem;
    color: var(--text-secondary);
    font-weight: normal;
  }
  
  .promotion-contact-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }
  
  .form-group label {
    font-size: 0.75rem;
    font-weight: 500;
    color: var(--text-primary);
  }
  
  .form-group input {
    padding: 0.5rem 0.75rem;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    background-color: var(--secondary-bg);
    color: var(--text-primary);
    font-size: 0.875rem;
    height: 36px;
  }
  
  .form-group input:focus {
    outline: none;
    border-color: var(--accent);
  }
  
  .submit-button {
    background-color: #de0013;
    color: white;
    border: none;
    border-radius: 100px;
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;
    height: 44px;
    width: 100%;
    margin-top: 1rem;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    transition: background-color 0.2s ease;
  }
  
  .submit-button:hover {
    background-color: #b1000f;
  }
  
  @media (max-width: 480px) {
    .promotion-modal-content {
      padding: 1rem;
      margin: 0.5rem;
      max-height: calc(100vh - 2rem);
      overflow-y: auto;
    }
  
    .promotion-modal-header h2 {
      font-size: 1.125rem;
    }
  
    .form-group input {
      font-size: 16px;
    }
  }
  
  @media (max-height: 600px) {
    .promotion-modal-content {
      max-height: calc(100vh - 2rem);
      overflow-y: auto;
    }
  }
  
  @media (prefers-reduced-motion: reduce) {
    .promotion-modal-backdrop,
    .promotion-modal-close,
    .submit-button {
      transition: none;
    }
  }