/* Base styles */
.dealership-page {
    background-color: var(--background);
    min-height: 100vh;
    padding-top: 80px;
  }
  
  .dealership-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
  }
  
  /* Dealer Header Section */
  .dealer-header {
    margin-bottom: 2rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid var(--border-color);
  }
  
  .inventory-title {
    font-size: 1.75rem;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 1rem;
    color: var(--text-primary);
  }
  
  .dealer-info {
    margin-top: 1rem;
    color: var(--text-secondary);
    font-size: 0.9375rem;
    line-height: 1.5;
  }
  
  .dealer-info p {
    margin: 0.25rem 0;
  }
  
  /* Filters & Sort Section */
  .filters-sort-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1.5rem;
    margin-bottom: 2rem;
  }
  
  .sort-options {
    display: flex;
    gap: 1rem;
    align-items: center;
    flex-grow: 1;
  }
  
  .sort-option {
    background: none;
    border: none;
    color: #808080;
    padding: 0;
    cursor: pointer;
    font-size: 12px;
    position: relative;
    transition: color 0.2s ease;
    white-space: nowrap;
  }
  
  .sort-option.active {
    color: var(--text-primary);
    text-decoration: underline;
  }
  
  .filter-dropdowns {
    display: flex;
    gap: 0.75rem;
    align-items: center;
  }
  
  .filter-dropdown {
    min-width: 115px;
  }
  
  .filter-select {
    width: 100%;
    padding: 0.5rem 0.75rem;
    font-size: 0.75rem;
    border-radius: 4px;
    background-color: #333;
    color: var(--text-primary);
    border: 1px solid #555;
    cursor: pointer;
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 0.5rem center;
    background-size: 0.75rem;
    padding-right: 1.5rem;
  }
  
  /* Car Grid */
  .car-grid {
    display: grid;
    grid-template-columns: repeat(4, 266px);
    gap: 1.5rem;
    width: 100%;
    justify-content: center;
  }
  
  .car-item-link {
    text-decoration: none;
    color: inherit;
  }
  
  .car-item {
    width: 266px;
    height: 280px;
    background-color: var(--car-item-bg);
    border-radius: 8px;
    overflow: hidden;
    transition: transform 0.2s;
    border: 1px solid var(--border-color);
    display: flex;
    flex-direction: column;
  }
  
  .car-item:hover {
    transform: translateY(-4px);
  }
  
  .car-item-image-container {
    position: relative;
    width: 100%;
    height: 177px;
    overflow: hidden;
  }
  
  .car-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .car-item-price {
    position: absolute;
    bottom: 6px;
    left: 6px;
    background-color: rgba(0, 0, 0, 0.75);
    color: white;
    padding: 3px 8px;  /* Reduced padding */
    border-radius: 4px;
    font-size: 0.875rem;  /* Smaller font size */
    font-weight: 600;  /* Slightly reduced font weight */
  }
  
  .car-info {
    padding: 1rem;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .car-title {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
    line-height: 1.2;
    max-height: 2.4em;
    overflow: hidden;
    color: var(--text-primary);
  }
  
  .car-details {
    color: var(--text-secondary);
    font-size: 0.875rem;
    white-space: pre-line;
    line-height: 1.4;
    max-height: 2.8em;
    overflow: hidden;
  }
  
  /* Pagination */
  .pagination-container {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
  }
  
  .pagination {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  
  .pagination button {
    height: 42px;
    padding: 0 1.5rem;
    background-color: var(--secondary-bg);
    border: none;
    border-radius: 35px;
    cursor: pointer;
    color: var(--text-primary);
    transition: background-color 0.2s;
    font-size: 0.875rem;
    font-weight: 500;
  }
  
  .pagination button:hover {
    background-color: var(--accent);
    color: white;
  }
  
  .pagination span {
    color: var(--text-secondary);
  }
  
  /* Theme-specific styles */
  [data-theme='light'] .filter-select {
    background-color: #fff;
    border: 1px solid #ccc;
    color: #000;
  }
  
  [data-theme='dark'] .filter-select {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="darkgrey" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="6 9 12 15 18 9"/></svg>');
  }
  
  /* Responsive Design */
  @media (max-width: 1200px) {
    .car-grid {
      grid-template-columns: repeat(3, 266px);
    }
  }
  
  @media (max-width: 1024px) {
    .dealership-content {
      padding: 1.5rem;
    }
    
    .inventory-title {
      font-size: 1.5rem;
    }
  }
  
  @media (max-width: 992px) {
    .car-grid {
      grid-template-columns: repeat(2, 266px);
    }
  }
  
  @media (max-width: 768px) {
    .dealer-header {
      margin-bottom: 1.5rem;
      padding-bottom: 1rem;
    }
  
    .dealer-info {
      font-size: 0.875rem;
    }
    
    .inventory-title {
      font-size: 1.375rem;
    }
  
    .filters-sort-container {
      flex-direction: column;
      gap: 1rem;
    }
  
    .sort-options {
      width: 100%;
      overflow-x: auto;
      padding-bottom: 0.5rem;
    }
  
    .filter-dropdowns {
      width: 100%;
      flex-wrap: wrap;
    }
  
    .filter-dropdown {
      flex: 1;
      min-width: 150px;
    }
  }
  
  @media (max-width: 600px) {
    .car-grid {
      grid-template-columns: 266px;
    }
  
    .pagination button {
      height: 38px;
      padding: 0 1rem;
      font-size: 0.8125rem;
    }
  }
  
  @media (max-width: 480px) {
    .dealership-content {
      padding: 1rem;
    }
  
    .dealer-header {
      margin-bottom: 1rem;
    }
    
    .inventory-title {
      font-size: 1.25rem;
    }

      .car-item-price {
        font-size: 0.8125rem;  /* Even smaller on mobile */
        padding: 2px 6px;  /* Smaller padding on mobile */
      }
  }
  
  /* Touch Device Optimizations */
  @media (hover: none) {
    .car-item:hover {
      transform: none;
    }
  
    .sort-option:hover {
      color: #808080;
    }
    
    .sort-option.active:hover {
      color: var(--text-primary);
    }
  
    .pagination button:hover {
      background-color: var(--secondary-bg);
      color: var(--text-primary);
    }
  }
  
  /* Accessibility */
  @media (prefers-reduced-motion: reduce) {
    .car-item,
    .sort-option {
      transition: none;
    }
  }
  
  /* Focus States */
  .filter-select:focus {
    outline: 2px solid var(--accent);
    outline-offset: 2px;
  }
  
  .sort-option:focus-visible {
    outline: 2px solid var(--accent);
    outline-offset: 2px;
    border-radius: 2px;
  }
  
  .pagination button:focus-visible {
    outline: 2px solid var(--accent);
    outline-offset: 2px;
  }