.home-page {
  font-family: Arial, sans-serif;
  background-color: var(--background);
  color: var(--text-primary);
  padding: 0.8rem;
}

.main-content {
  max-width: 1200px;
  margin: -75px auto;
  padding: 0.8rem;
}

.featured-section {
  position: relative;
  margin-bottom: 2rem;
}

.featured-slide {
  opacity: 1;
  position: relative;
  width: 100%;
  pointer-events: all;
  padding-top: 130px;
}

.featured-card {
  display: grid;
  grid-template-columns: 65% 35%;
  gap: 0.5rem;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
}

.featured-main {
  position: relative;
  height: 330px;
  overflow: hidden;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.featured-main-link {
  display: block;
  text-decoration: none;
  color: inherit;
  height: 100%;
}

.featured-main img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.time-badge {
  position: absolute;
  top: 1rem;
  left: 1rem;
  background-color: white;
  color: black;
  padding: 0.25rem 0.75rem;
  border-radius: 4px;
  font-weight: 600;
  font-size: 0.875rem;
  text-transform: uppercase;
  z-index: 1;
}

.featured-title {
  margin: 0 0 0.5rem 0;
  font-size: 20px;
  font-weight: 600;
  color: white;
}

.featured-specs {
  font-size: 1rem;
  opacity: 0.9;
}

.featured-thumbnails {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(2, 1fr);
  gap: 0.5rem;
  height: 330px;
  overflow-y: hidden;
  position: relative;
}

.featured-secondary,
.featured-secondary img,
.featured-secondary.text-content,
.text-content {
  border-radius: 4px;
  overflow: hidden;
}

.featured-secondary {
  height: 161px;
  position: relative;
}

.featured-secondary img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.featured-secondary:nth-child(1),
.featured-secondary:nth-child(2),
.featured-secondary:nth-child(3),
.featured-secondary:nth-child(4) {
  height: 161px;
}

.featured-secondary:hover img {
  transform: scale(1.05);
}

.text-content {
  background-color: var(--secondary-bg);
  padding: 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.thumbnail-content {
  text-decoration: none;
  color: var(--text-primary);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.thumbnail-content h3 {
  font-size: 0.75rem;
  font-weight: 600;
  margin: -2px;
  line-height: 1.4;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.thumbnail-date {
  font-size: 0.8rem;
  color: var(--text-secondary);
  margin-top: 0.5rem;
}

.filters-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  width: 100%;
}

.home-filters-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 2rem;
}

.filter-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: center;
}

.filter-button {
  height: 32px;
  padding: 8px 16px;
  border: none;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  border-radius: 35px;
  background-color: var(--secondary-bg);
  color: var(--text-primary);
}

.filter-button:hover {
  background-color: var(--accent);
  color: white;
}

.sort-options {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  align-items: center;
}

.sort-options.home {
  border-bottom: none;
  padding-bottom: 0;
  margin-left: auto;
  justify-content: flex-end;
}

.sort-button {
  height: 32px;
  padding: 8px 16px;
  background-color: var(--secondary-bg);
  color: var(--text-primary);
  border: 1px solid rgba(255, 255, 255, 0.1);
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  border-radius: 35px;
}

.sort-button:hover {
  background-color: var(--accent);
  color: white;
  border-color: var(--accent);
}

.mobile-filters {
  display: none;
  gap: 0.5rem;
  padding: 1rem 2rem;
  background-color: var(--background);
}

.mobile-filter-group {
  position: relative;
  width: 100%;
}

.mobile-filter-button {
  width: 100%;
  background-color: var(--secondary-bg);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  padding: 0.75rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--text-primary);
  font-size: 0.875rem;
  cursor: pointer;
}

.mobile-filter-button .chevron {
  transition: transform 0.2s ease;
  opacity: 0.5;
}

.mobile-filter-button .chevron.open {
  transform: rotate(180deg);
}

.mobile-filter-dropdown {
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  right: 0;
  background-color: var(--background);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  z-index: 10;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.mobile-filter-option {
  width: 100%;
  padding: 0.75rem 1rem;
  background: none;
  border: none;
  text-align: left;
  color: var(--text-primary);
  font-size: 0.875rem;
  cursor: pointer;
  border-bottom: 1px solid var(--border-color);
}

.mobile-filter-option:last-child {
  border-bottom: none;
}

.mobile-filter-option.active {
  background-color: var(--accent);
  color: white;
}

.mobile-filter-option:hover {
  background-color: var(--secondary-bg);
}

.car-grid {
  display: grid;
  grid-template-columns: repeat(4, 266px);
  gap: 1.5rem;
  width: 100%;
  justify-content: center;
}

.car-item {
  width: 266px;
  height: 280px;
  background-color: var(--car-item-bg);
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.2s;
  border: 0px solid rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}


.car-item:hover {
  transform: translateY(-4px);
}

.car-item-image-container {
  position: relative;
  width: 100%;
  height: 177px;
  overflow: hidden;
}

.car-item img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.car-item-price {
  position: absolute;
  bottom: 6px;
  left: 6px;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 4px 6px;
  border-radius: 4px;
  font-weight: 600;
}

.car-info {
  padding: 1rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.car-title {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  line-height: 1.2;
  max-height: 2.4em;
  overflow: hidden;
  color: var(--text-primary);
}

.car-details {
  color: var(--text-secondary);
  font-size: 0.875rem;
  white-space: pre-line;
  line-height: 1.4;
  max-height: 2.8em;
  overflow: hidden;
}

.podcast-section {
  position: relative;
  padding: 1.5rem 0;
  margin-bottom: 1.5rem;
}

.podcast-container {
  max-width: 1200px;
  margin: 0 auto;
}

.podcast-title {
  font-size: 1.75rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  color: #ffffff;
}

[data-theme=light] .podcast-title {
  color: #333333;
}

.podcast-grid {
  display: flex;
  gap: 0.75rem;
  overflow-x: auto;
  padding-bottom: 1.5rem;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
}

.podcast-grid::-webkit-scrollbar {
  display: none;
}

.podcast-card {
  min-width: 220px;
  width: 220px;
  height: 280px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  flex-shrink: 0;
  cursor: pointer;
  background-color: #1a1a1a;
  transition: transform 0.2s ease;
}

.podcast-image-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.podcast-image-container img {
  width: 100%;
  object-fit: cover;
}

.podcast-logo-card img {
  height: 100%;
}

.podcast-content {
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1.25rem;
  background: #000000;
  color: white;
  height: 100%;
}

.podcast-content h3 {
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 0.375rem;
  color: white;
}

.podcast-content p {
  font-size: 0.875rem;
  opacity: 0.8;
  min-height: 45px;
}

.podcast-date {
  font-size: 0.875rem;
  color: #ffffff;
  opacity: 0.7;
}

.video-play-button {
  display: none;
}

.podcast-scroll-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;
  transition: all 0.2s ease;
}

.podcast-scroll-button:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.podcast-scroll-button.left {
  left: 0.75rem;
}

.podcast-scroll-button.right {
  right: 0.75rem;
}

@media (max-width: 1200px) {
  .featured-card {
    grid-template-columns: 60% 40%;
  }

  .car-grid {
    grid-template-columns: repeat(3, 266px);
  }
}

@media (max-width: 1024px) {
  .podcast-card {
    min-width: 200px;
    width: 200px;
    height: 260px;
  }
  
  .podcast-container {
    padding: 0 1.5rem;
  }
}

@media (max-width: 768px) {
  .mobile-filters {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .filters-wrapper,
  .filter-buttons,
  .sort-options {
    display: none;
  }

  .featured-card {
    grid-template-columns: 1fr;
  }

  .featured-thumbnails {height: auto;
  }

  .car-grid {
    grid-template-columns: repeat(2, 266px);
  }

  .podcast-section {
    padding: 1rem 0;
  }

  .podcast-title {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .podcast-card {
    min-width: 180px;
    width: 180px;
    height: 240px;
  }

  .podcast-content {
    padding: 1rem;
  }

  .podcast-content h3 {
    font-size: 1rem;
  }
}


@media (max-width: 600px) {
  .home-page .car-grid {
    grid-template-columns: repeat(2, 1fr) !important;
    padding: 1rem 2rem !important;
  }
  
  .home-page .car-item {
    width: 100% !important;
    height: 240px !important;
  }
  
  .home-page .car-item-image-container {
    height: unset !important;
    aspect-ratio: 640 / 480 !important;
  }
}

@media (max-width: 480px) {
  .featured-card {
    gap: 0.25rem;
  }

  .featured-main {
    height: 250px;
  }

  .car-grid {
    grid-template-columns: 266px;
  }

  .mobile-filters {
    padding: 1rem;
  }

  .home-page .car-grid {
    padding: 1rem !important;
  }

  .podcast-container {
    padding: 0 1rem;
  }

  .podcast-card {
    min-width: 160px;
    width: 160px;
    height: 220px;
  }

  .podcast-content {
    padding: 0.875rem;
  }

  .podcast-content h3 {
    font-size: 0.875rem;
  }

  .podcast-scroll-button {
    width: 28px;
    height: 28px;
  }
}

@media (hover: none) {
  .featured-slide,
  .featured-secondary img,
  .car-item,
  .nav-button,
  .side-image img,
  .main-image-nav,
  .modal-nav-btn,
  .contact-button {
    transition: none;
  }
}

@media (prefers-reduced-motion: reduce) {
  * {
    transition: none !important;
  }
}

.filter-button:focus-visible,
.sort-button:focus-visible,
.mobile-filter-button:focus-visible,
.mobile-filter-option:focus-visible {
  outline: 2px solid var(--accent);
  outline-offset: 2px;
}

.sort-button:focus-visible,
.filter-button:focus-visible,
.podcast-scroll-button:focus-visible {
  outline: 2px solid var(--accent);
  outline-offset: 2px;
}

.home-page .main-content {
  padding: 0.8rem;
}
