.news-page {
    background-color: var(--background);
    min-height: 100vh;
    padding-top: 72px;
  }
  
  .news-header {
    background-color: var(--accent);
    padding: 3rem 0;
    margin-bottom: 2rem;
    color: white;
  }
  
  .news-title {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
    color: white;
  }

  [data-theme=light] .home-page .news-title {
    color: #000 !important;
}
  
  .news-subtitle {
    font-size: 1.25rem;
    opacity: 0.9;
  }
  
  .content-wrapper {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
  }
  
  .news-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    margin-bottom: 3rem;
  }
  
  .news-card {
    background-color: var(--secondary-bg);
    border-radius: 8px;
    overflow: hidden;
    text-decoration: none;
    transition: transform 0.2s ease;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .news-card:hover {
    transform: translateY(-4px);
  }
  
  .news-image-container {
    width: 100%;
    padding-top: 56.25%; /* 16:9 aspect ratio */
    position: relative;
    overflow: hidden;
  }
  
  .news-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .news-content {
    padding: 1.5rem;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
  
  .news-card-title {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 1rem;
    color: var(--text-primary);
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  
  .news-meta {
    margin-bottom: 1rem;
  }
  
  .news-date {
    color: var(--text-secondary);
    font-size: 0.875rem;
  }
  
  .news-excerpt {
    color: var(--text-secondary);
    font-size: 0.9375rem;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-top: auto;
  }
  
  .load-more {
    display: flex;
    justify-content: center;
    margin: 2rem 0;
  }
  
  .load-more-button {
    background-color: var(--accent);
    color: white;
    border: none;
    padding: 1rem 2rem;
    border-radius: 4px;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .load-more-button:hover {
    background-color: var(--accent-dark);
  }
  
  .loading-state {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
  }
  
  .error-state {
    text-align: center;
    padding: 2rem;
    color: var(--text-secondary);
  }
  
  /* Responsive Design */
  @media (max-width: 1024px) {
    .news-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 768px) {
    .news-header {
      padding: 2rem 0;
    }
  
    .news-title {
      font-size: 2rem;
    }
  
    .content-wrapper {
      padding: 0 1rem;
    }
  }
  
  @media (max-width: 480px) {
    .news-grid {
      grid-template-columns: 1fr;
      gap: 1.5rem;
    }
  
    .news-title {
      font-size: 1.75rem;
    }
  
    .news-subtitle {
      font-size: 1rem;
    }
  
    .news-card-title {
      font-size: 1.125rem;
    }
  
    .load-more-button {
      width: 100%;
      padding: 0.875rem;
    }
  }
  
  /* Accessibility */
  @media (prefers-reduced-motion: reduce) {
    .news-card,
    .load-more-button {
      transition: none;
    }
  }
  
  .news-card:focus-visible,
  .load-more-button:focus-visible {
    outline: 2px solid var(--accent);
    outline-offset: 2px;
  }