.news-page {
    background-color: var(--background);
    min-height: 100vh;
    padding-top: 80px;
  }

  .news-title {
    font-size: 2.5rem;
    font-weight: 700;
    color: white !important; /* Force white color */
}

/* Ensure it stays white even in light mode */
[data-theme='light'] .news-title {
    color: white !important;
} 
  
  .article-content {
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
  }
  
  .article-header {
    margin-bottom: 2rem;
  }
  
  .featured-image-container {
    margin: -2rem -2rem 2rem;
    height: 400px;
    overflow: hidden;
  }
  
  .featured-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .article-meta {
    margin-bottom: 1rem;
  }
  
  .article-date {
    color: var(--text-secondary);
    font-size: 0.875rem;
  }
  
  .article-title {
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 1rem;
    color: var(--text-primary);
  }
  
  .article-body {
    font-size: 1.125rem;
    line-height: 1.8;
    color: var(--text-primary);
  }
  
  .article-body p {
    margin-bottom: 1.5rem;
  }
  
  .article-body img {
    max-width: 100%;
    height: auto;
    margin: 2rem 0;
    border-radius: 8px;
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .article-content {
      padding: 1.5rem;
    }
  
    .featured-image-container {
      height: 300px;
      margin: -1.5rem -1.5rem 1.5rem;
    }
  
    .article-title {
      font-size: 2rem;
    }
  }
  
  @media (max-width: 480px) {
    .article-content {
      padding: 1rem;
    }
  
    .featured-image-container {
      height: 200px;
      margin: -1rem -1rem 1rem;
    }
  
    .article-title {
      font-size: 1.5rem;
    }
  
    .article-body {
      font-size: 1rem;
    }
  }