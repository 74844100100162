.photo-gallery-page {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--background);
  color: var(--text-primary);
  z-index: 1100;
  overflow-y: auto;
}

.gallery-header {
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: var(--header-bg);
  border-bottom: 1px solid var(--secondary-bg);
  z-index: 1;
}

.gallery-title {
  font-size: 1.2rem;
  font-weight: 500;
  margin-right: 1rem;
  color: var(--text-primary);
}

.close-button {
  background: none;
  border: none;
  color: var(--text-primary);
  padding: 0.5rem;
  cursor: pointer;
  opacity: 0.8;
  transition: opacity 0.2s ease;
}

.close-button:hover {
  opacity: 1;
}

.category-tabs {
  display: flex;
  gap: 1rem;
}

.category-tab {
  background: none;
  border: none;
  color: var(--text-secondary);
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-size: 0.9rem;
  transition: all 0.2s ease;
}

.category-tab:hover {
  color: var(--text-primary);
}

.category-tab.active {
  color: var(--text-primary);
  border-bottom: 2px solid var(--accent);
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 0.5rem;
  padding: 0.5rem;
  background-color: var(--background);
}

.gallery-item {
  position: relative;
  aspect-ratio: 4/3;
  overflow: hidden;
  background-color: var(--secondary-bg);
  cursor: pointer;
  border-radius: 4px;
}

.gallery-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.gallery-item:hover img {
  transform: scale(1.05);
}

/* Gallery Modal Styles */
.gallery-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.95);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1200;
  padding: 2rem;
  cursor: pointer;
}

.modal-content {
  position: relative;
  max-width: 90vw;
  max-height: 90vh;
  overflow: hidden;
  cursor: default;
  overflow-y: scroll;
}

.modal-content img {
  display: block;
  max-width: 100%;
  max-height: 90vh;
  margin: 0 auto;
  object-fit: contain;
}

.modal-close-btn {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  font-size: 2rem;
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: background-color 0.2s ease;
  z-index: 1201;
}

.modal-nav-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  font-size: 3rem;
  cursor: pointer;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: background-color 0.2s ease;
  z-index: 1201;
}

.modal-nav-btn.prev {
  left: 1rem;
}

.modal-nav-btn.next {
  right: 1rem;
}

.modal-close-btn:hover,
.modal-nav-btn:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

/* Loading and Error States */
.loading-state,
.error-state {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--text-primary);
  text-align: center;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .modal-nav-btn {
    width: 50px;
    height: 50px;
    font-size: 2.5rem;
  }

  .gallery-modal {
    padding: 1.5rem;
  }
}

@media (max-width: 768px) {
  .gallery-grid {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }

  .category-tabs {
    gap: 0.5rem;
  }

  .category-tab {
    padding: 0.5rem;
    font-size: 0.8rem;
  }

  .modal-nav-btn {
    width: 40px;
    height: 40px;
    font-size: 2rem;
  }

  .modal-close-btn {
    width: 36px;
    height: 36px;
    font-size: 1.5rem;
  }
}

@media (max-width: 480px) {
  .gallery-grid {
    grid-template-columns: 1fr;
  }

  .gallery-modal {
    padding: 1rem;
  }

  .modal-nav-btn {
    width: 36px;
    height: 36px;
    font-size: 1.5rem;
  }

  .gallery-title {
    display: none;
  }
}

/* Touch Device Optimizations */
@media (hover: none) {
  .gallery-item:hover img {
    transform: none;
  }

  .modal-nav-btn:hover,
  .modal-close-btn:hover {
    background-color: rgba(0, 0, 0, 0.5);
  }
}

/* Dark Mode Specific Styles */
[data-theme='dark'] .gallery-header {
  border-bottom-color: rgba(255, 255, 255, 0.1);
}

[data-theme='dark'] .gallery-item {
  background-color: rgba(255, 255, 255, 0.05);
}