:root {
  --background: #ffffff;
  --text-primary: #333333;
  --text-secondary: #666666;
  --accent: #de0013;
  --accent-dark: #b1000f;  
  --header-bg: #000000;
  --secondary-bg: #e9ecef;
  --footer-bg: #1a1a1a;
  --footer-text: #ffffff;
  --footer-secondary: #666666;
  --border-color: rgba(0, 0, 0, 0.1);
  --nav-bg: #2a2a2a;
  --nav-hover: #333333;
  --nav-text: #ffffff;
  --nav-border: var(--accent);
  --search-bg: #2a2a2a;
  --theme-toggle-bg: #2a2a2a;
  --loader-bg: #000000;
  --loader-text: #ffffff;
  --loader-needle: #de0013;
  --loader-ticks: #ffffff;
}

[data-theme='dark'] {
  --background: #1a1a1a !important;
  --text-primary: #ffffff;
  --text-secondary: #b3b3b3;
  --accent: #de0013;
  --accent-dark: #ff1a2d;  
  --header-bg: #000000;
  --secondary-bg: #333333;
  --footer-bg: #1a1a1a;
  --footer-text: #ffffff;
  --footer-secondary: #666666;
  --border-color: rgba(255, 255, 255, 0.1);
  --nav-bg: #2a2a2a;
  --nav-hover: #333333;
  --nav-text: #ffffff;
  --nav-border: var(--accent);
  --search-bg: #2a2a2a;
  --theme-toggle-bg: #2a2a2a;
  --loader-bg: #000000;
  --loader-text: #ffffff;
  --loader-needle: #de0013;
  --loader-ticks: #ffffff;
}

body {
  background-color: var(--background);
  color: var(--text-primary);
  transition: all 0.3s ease;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  font-family: Arial, sans-serif;
  line-height: 1.6;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  width: auto;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-wrapper {
  flex: 1 0 auto;
}

a {
  color: var(--accent);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

h1, h2, h3, h4, h5, h6 {
  margin-bottom: 1rem;
  color: var(--text-primary);
}

img {
  max-width: 100%;
  height: auto;
}

.car-title {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  line-height: 1.2;
  max-height: 2.4em;
  overflow: hidden;
}

.detail-page-title {
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 1.5rem;
  color: var(--text-primary);
  letter-spacing: -0.5px;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.car-item {
  width: 266px;
  height: 280px;
  background-color: transparent;
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.2s;
  border: 1px solid var(--border-color);
  display: flex;
  flex-direction: column;
}

.car-item:hover {
  transform: translateY(-4px);
}

.btn {
  display: inline-block;
  background: var(--accent);
  color: #fff;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  text-align: center;
}

.btn:hover {
  background: var(--accent-dark);
  text-decoration: none;
}

.theme-toggle {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  margin-left: auto;
}

.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--loader-bg);
  z-index: 9999;
}

.speedometer {
  position: relative;
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
}

.gauge {
  position: absolute;
  width: 100%;
  height: 100%;
}

.ticks {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.tick {
  position: absolute;
  width: 2px;
  height: 8px;
  background-color: var(--loader-ticks);
  left: 50%;
  top: 10px;
  transform-origin: 50% 90px;
}

.needle {
  position: absolute;
  width: 4px;
  height: 85px;
  background-color: var(--loader-needle);
  left: calc(50% - 2px);
  bottom: 50%;
  transform-origin: bottom center;
  transition: transform 0.2s ease;
  border-radius: 2px;
}

.center-point {
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: var(--loader-needle);
  border-radius: 50%;
  left: calc(50% - 10px);
  top: calc(50% - 10px);
}

.percentage {
  font-size: 32px;
  font-weight: 600;
  color: var(--loader-text);
  margin-top: 80px;
}

.loading-text {
  font-size: 18px;
  font-weight: 500;
  color: var(--loader-text);
  margin-top: 1rem;
}

.card {
  background-color: var(--background);
  border-radius: 8px;
  overflow: hidden;
}

.input-group {
  margin-bottom: 1rem;
}

.input-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: var(--text-primary);
}

.input-group input,
.input-group select,
.input-group textarea {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid var(--secondary-bg);
  border-radius: 4px;
  background-color: var(--background);
  color: var(--text-primary);
}

.btn-primary {
  background-color: var(--accent);
  color: white;
}

.btn-secondary {
  background-color: var(--secondary-bg);
  color: var(--text-primary);
}

@media (max-width: 1024px) {
  .detail-page-title {
    font-size: 1.5rem;
  }
}

@media (max-width: 768px) {
  .detail-page-title {
    font-size: 1.375rem;
  }
}

@media (max-width: 480px) {
  .detail-page-title {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
  
  .car-title {
    font-size: 0.9375rem;
  }
}

@media (hover: none) {
  .car-item:hover {
    transform: none;
  }
}

@media (prefers-reduced-motion: reduce) {
  * {
    transition: none !important;
  }
}

a:focus-visible,
button:focus-visible,
input:focus-visible,
select:focus-visible,
textarea:focus-visible {
  outline: 2px solid var(--accent);
  outline-offset: 2px;
}

.search-bar-content input:focus-visible {
  outline: none;
}

@media (max-width: 768px) {
  .app-container {
    touch-action: pan-y pinch-zoom;
    overflow-x: hidden;
  }
}

@media (prefers-reduced-motion: reduce) {
  .app-container {
    transition: none !important;
  }
}



