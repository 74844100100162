.inventory-page {
  background-color: var(--background);
  color: var(--text-primary);
  padding-top: 80px;
}

.inventory-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.inventory-title {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 2rem;
  color: var(--text-primary);
}

/* Model Filters Section */
.model-filters {
  margin-bottom: 1.5rem;
  position: relative;
  background-color: #181818;
  border-radius: 8px;
  padding: 0.5rem 2.5rem;
  display: flex;
  align-items: center;
  min-height: 54px;
}

[data-theme=light] .model-filters {
  background-color: #fff;
  background-color: var(--filter-bg-light);
  color: #000;
  color: var(--filter-text-light);
}

.model-filters-scroll {
  display: flex;
  gap: 0.5rem;
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  scroll-behavior: smooth;
  width: 100%;
  padding: 0.5rem 0;
  position: relative;
}

.model-filters-scroll::-webkit-scrollbar {
  display: none;
}

.scroll-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  color: white;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;
}

[data-theme=light] .scroll-button {
  color: #555;
}

.scroll-button.left {
  left: 0.5rem;
}

.scroll-button.right {
  right: 0.5rem;
}

/* Updated model filter button styles */
.model-filter-btn {
  background-color: #333;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  color: var(--text-primary);
  font-size: 12px;
  cursor: pointer;
  white-space: nowrap;
  transition: all 0.2s ease;
  font-weight: 500;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #555;
}

[data-theme=light] .model-filter-btn {
  background-color: #f8f9fa;
  border: 1px solid #ccc;
  border: 1px solid var(--filter-border-light);
  color: #000;
  color: var(--filter-text-light);
}

.model-filter-btn:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.model-filter-btn.active {
  background-color: white;
  color: black;
}

[data-theme='dark'] .model-filter-btn.active {
  color: black;
}

/* Filters & Sort Container */
.filters-sort-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;
  margin-bottom: 2rem;
}

/* Sort Options */
.sort-options {
  display: flex;
  gap: 1rem;
  align-items: center;
  flex-grow: 1;
}

.sort-option {
  background: none;
  border: none;
  color: #808080;
  padding: 0;
  cursor: pointer;
  font-size: 12px;
  position: relative;
  transition: color 0.2s ease;
  white-space: nowrap;
}

.sort-option.active {
  color: var(--text-primary);
  text-decoration: underline;
}

/* Filter Dropdowns */
.filter-dropdowns {
  display: flex;
  gap: 0.75rem;
  align-items: center;
}

.filter-dropdown {
  min-width: 115px;
}

.filter-select {
  width: 100%;
  padding: 0.5rem 0.75rem;
  font-size: 0.75rem;
  border-radius: 4px;
  background-color: #333;
  color: var(--text-primary);
  border: 1px solid #555;
  cursor: pointer;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 0.5rem center;
  background-size: 0.75rem;
  padding-right: 1.5rem;
}

[data-theme=light] .filter-select {
  background-color: #fff;
  background-color: var(--filter-bg-light);
  border: 1px solid #ccc;
  border: 1px solid var(--filter-border-light);
  color: #000;
  color: var(--filter-text-light);
}

[data-theme=dark] .filter-select {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="darkgrey" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="6 9 12 15 18 9"/></svg>');
}

/* Car Grid */
.car-grid {
  display: grid;
  grid-template-columns: repeat(4, 266px);
  gap: 1.5rem;
  width: 100%;
  justify-content: center;
  padding: 0;
}



.car-item {
  width: 266px;
  height: 280px;
  background-color: var(--car-item-bg);
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.2s;
  border: 0px solid rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.car-item:hover {
  transform: translateY(-4px);
}

.car-item-image-container {
  position: relative;
  width: 100%;
  height: 177px;
  overflow: hidden;
}

.car-item img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.car-item-price {
  position: absolute;
  bottom: 6px;
  left: 6px;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 4px 6px;
  border-radius: 4px;
  font-weight: 600;
}

.car-info {
  padding: 1rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.car-title {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  line-height: 1.2;
  max-height: 2.4em;
  overflow: hidden;
}

.car-details {
  color: var(--text-secondary);
  font-size: 0.875rem;
  white-space: pre-line;
  line-height: 1.4;
  max-height: 2.8em;
  overflow: hidden;
}

/* Pagination */
.pagination-container {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.pagination {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.pagination button {
  height: 42px;
  padding: 0 1.5rem;
  background-color: var(--secondary-bg);
  border: none;
  border-radius: 35px;
  cursor: pointer;
  color: var(--text-primary);
  transition: background-color 0.2s;
  font-size: 0.875rem;
  font-weight: 500;
}

.pagination button:hover {
  background-color: var(--accent);
  color: white;
}

.pagination span {
  color: var(--text-secondary);
}

/* Loading and Error States */
.loading-state,
.error-state {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
  font-size: 1.2rem;
  color: var(--text-secondary);
}

/* Responsive Design */
@media (max-width: 1200px) {
  .car-grid {
    grid-template-columns: repeat(3, 266px);
  }
}

@media (max-width: 992px) {
  .car-grid {
    grid-template-columns: repeat(2, 266px);
  }
}

@media (max-width: 768px) {
  .inventory-content {
    padding: 1rem;
  }

  .model-filters {
    padding: 0.5rem 2rem;
  }

  .filters-sort-container {
    flex-direction: column;
    gap: 1rem;
  }

  .sort-options {
    width: 100%;
    overflow-x: auto;
    padding-bottom: 0.5rem;
  }

  .filter-dropdowns {
    width: 100%;
    flex-wrap: wrap;
  }

  .filter-dropdown {
    flex: 1;
    min-width: 150px;
  }
}

@media (max-width: 600px) { 
  .car-grid { 
          grid-template-columns: repeat(2, 1fr) !important;
          padding: 1rem 2rem !important; 
      }
      .car-item {
          width: 100% !important;
          height: 240px !important; 
      }
      .car-item-image-container {
          height: unset !important;
          aspect-ratio: 640 / 480 !important;
      }
  
      .inventory-content .car-grid {
          padding: 0 !important;
      }
}

/* Touch Device Optimizations */
@media (hover: none) {
  .car-item:hover {
    transform: none;
  }

  .model-filter-btn:hover {
    background-color: transparent;
  }

  .model-filter-btn.active {
    background-color: white;
  }

  .sort-option:hover {
    color: #808080;
  }
  
  .sort-option.active:hover {
    color: var(--text-primary);
  }

  .pagination button:hover {
    background-color: var(--secondary-bg);
    color: var(--text-primary);
  }
}

[data-theme='dark'] .model-filter-btn {
  color: var(--text-primary);
}

[data-theme='dark'] .car-item {
  background-color: var(--car-item-bg);
  border-color: rgba(255, 255, 255, 0.1);
}

/* Accessibility */
@media (prefers-reduced-motion: reduce) {
  .car-item,
  .model-filter-btn,
  .sort-option,
  .model-filters-scroll {
    transition: none;
  }
}