.dealer-listing-page {
    padding-top: 80px;
    min-height: 100vh;
    background-color: var(--background);
}
  
.dealer-listing-header {
    background-color: var(--accent);
    padding: 3rem 2rem;
    color: white;
    margin-bottom: 2rem;
    position: relative;
}

.back-button {
    position: absolute;
    left: 24px;
    top: 10px;
    background: none;
    border: none;
    color: white;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    padding: 0.5rem;
    font-size: 12px;
    transition: opacity 0.2s ease;
}

.back-button:hover {
    opacity: 0.8;
}
  
.dealer-listing-header h1 {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
    color: white;
}
  
.dealer-grid {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1rem;
}
  
.dealer-pill {
    background-color: var(--secondary-bg);
    border-radius: 8px;
    padding: 1.5rem;
    cursor: pointer;
    transition: transform 0.2s ease;
}
  
.dealer-pill:hover {
    transform: translateY(-4px);
}
  
.dealer-pill h2 {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
    color: var(--text-primary);
}
  
.dealer-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--text-secondary);
    font-size: 0.875rem;
}
  
.dealer-count {
    color: var(--accent);
    font-weight: 500;
}
  
@media (max-width: 768px) {
    .dealer-listing-header {
        padding: 2rem 1rem;
    }
  
    .dealer-listing-header h1 {
        font-size: 1.75rem;
    }
  
    .dealer-grid {
        padding: 0 1rem;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }

    .back-button {
        display: none;
    }
}
  
@media (max-width: 480px) {
    .dealer-listing-header {
        padding: 1.5rem 1rem;
    }
  
    .dealer-listing-header h1 {
        font-size: 1.5rem;
    }
  
    .dealer-grid {
        grid-template-columns: 1fr;
    }
}

@media (prefers-reduced-motion: reduce) {
    .back-button {
        transition: none;
    }
    
    .dealer-pill {
        transition: none;
    }
}