.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal-content {
    background-color: var(--background);
    border-radius: 8px;
    width: 450px;  /* Increased from 400px */
    max-width: 90%;
    max-height: 85vh;
    overflow-y: auto;
    position: relative;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid var(--border-color);
}

.modal-header h2 {
    margin: 0;
    font-size: 1.25rem;
    color: var(--text-primary);
}

.close-button {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    padding: 0;
    color: var(--text-primary);
}

.car-info {
    padding: 12px 16px;
}

.car-info p {
    margin: 4px 0;
    color: var(--text-secondary);
    font-size: 0.9rem;
}

.contact-form {
    padding: 16px;
}

.form-group {
    margin-bottom: 12px;
}

.form-group input,
.form-group select,
.form-group textarea {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    font-size: 0.9rem;
    background-color: var(--background);  /* Match theme background */
    color: var(--text-primary);  /* Match theme text color */
}

/* Light mode specific input styles */
:root[data-theme='light'] .form-group input,
:root[data-theme='light'] .form-group select,
:root[data-theme='light'] .form-group textarea {
    background-color: #ffffff;
    border-color: #e0e0e0;
}

/* Dark mode specific input styles */
:root[data-theme='dark'] .form-group input,
:root[data-theme='dark'] .form-group select,
:root[data-theme='dark'] .form-group textarea {
    background-color: #2a2a2a;
    border-color: #404040;
}

/* Focus states for form elements */
.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
    outline: none;
    border-color: var(--accent);
    box-shadow: 0 0 0 1px var(--accent);
}

.form-group textarea {
    height: 80px;
    resize: vertical;
    min-height: 60px;
}

.form-group.checkbox {
    display: flex;
    align-items: center;
    gap: 8px;
    flex-direction: row;
}

.form-group.checkbox input {
    width: auto;
}

.form-group.checkbox label {
    font-size: 0.9rem;
    color: var(--text-primary);
}

.error {
    color: var(--accent);
    font-size: 0.75rem;
    margin-top: 4px;
    display: block;
}

.submit-button {
    width: 100%;
    padding: 10px;
    background-color: var(--accent);
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 0.9rem;
    font-weight: 600;
    cursor: pointer;
    margin-top: 8px;
}

.submit-button:hover {
    background-color: var(--accent-dark);
}

.terms {
    margin-top: 12px;
    font-size: 0.75rem;
    color: var(--text-secondary);
    text-align: center;
    padding: 0 16px;
}

.terms a {
    color: var(--accent);
    text-decoration: none;
}

.terms a:hover {
    text-decoration: underline;
}

/* Placeholder text colors */
.form-group input::placeholder,
.form-group textarea::placeholder {
    color: var(--text-secondary);
    opacity: 0.7;
}

/* Dark mode styles */
[data-theme='dark'] .modal-content {
    border: 1px solid rgba(255, 255, 255, 0.1);
}

/* Responsive styles */
@media (max-width: 480px) {
    .modal-content {
        width: 95%;
        margin: 8px;
    }

    .modal-header {
        padding: 12px;
    }

    .contact-form {
        padding: 12px;
    }
}